import { combineReducers } from 'redux'

import user from './authentication/reducer'
import providers from './provider/reducer'
import error from './error/reducer'
import loading from './loading/reducer'
import categories from './categories/reducer'
import agencies from './agency/reducer'
import responsibles from './responsible/reducer'
import reasons from './reasons/reducer'
import utils from './utils/reducer'
import serviceOrders from './service-orders/reducer'
import form from './form/reducer'
import historyLogs from './history-logs/reducer'
import budget from './budget/reducer'
import widgets from './widgets/reducer'
import messages from './messages/reducer'
import company from './company/reducer'
import helpRequests from './helpRequests/reducer'
import property from './property/reducer'
import notifications from './notifications/reducer'
import finance from './finance/reducer'
import menuItems from './menu-items/reducer'
import dayPeriods from './day-period/reducer'
import holidays from './holiday/reducer'
import businessTime from './businessTime/reducer'
import lgpdLinks from './lgpdLinks/reducer'
import payments from './paymentAdvance/reducer'
import tips from './tip/reducer'
import tradesmanSequences from './tradesman-sequence/reducer'
import businessFronts from './businessFront/reducer'
import messaging from './mensageria/reducer'
import classification from './classification/reducer'
import agencyVip from './agencyVip/reducer'
import budgetTemplate from './budgetTemplate/reducer'
import { profiles } from './profile/reducer'
import { groups } from './group/reducer'
import { email } from './email/reducer'

const rootReducer = combineReducers({
  agencies,
  error,
  form,
  loading,
  providers,
  serviceOrders,
  categories,
  user,
  utils,
  responsibles,
  reasons,
  historyLogs,
  budget,
  widgets,
  messages,
  company,
  helpRequests,
  property,
  notifications,
  finance,
  menuItems,
  dayPeriods,
  lgpdLinks,
  holidays,
  businessTime,
  payments,
  tips,
  tradesmanSequences,
  messaging,
  businessFronts,
  classification,
  agencyVip,
  budgetTemplate,
  profiles,
  groups,
  email,
})

export default rootReducer
