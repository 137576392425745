import React from 'react'
import { Redirect, Router as ReachRouter } from '@reach/router'

import Widgets from '_views/widgets'
import HomeTradesman from '_views/home-tradesman'
import { FINANCE_ROUTES, ROUTES, WIDGETS_ROUTE } from '_utils/constants'
import App from '_views/app'
import Login from '_views/login'
import PasswordRecover from './views/login/password-recover'
import ServiceOrder from '_views/service-order'
import BudgetView from '_views/budget-view'
import BudgetPDF from '_views/service-order/budget-pdf'
import Agency from '_views/agency'
import AgencyUser from '_views/agency-user'
import Provider from '_views/provider'
import ManageProviders from '_views/manage-providers'
import ManageAgencies from '_views/manage-agencies'
import ManageInstallments from '_views/finance/manage-installments'
import RecoverPassword from '_views/recover-password'
import ServiceOrderDetails from '_views/service-order-details'
// import ExpiredLink from '_views/expired-link'
import ApplicantValuation from '_views/avaliacao-solicitante'
import ApplicantValuationConfirm from '_views/avaliacao-solicitante-confirm'
import ApprovedBudgetProvider from '_views/manage-budget-provider/approved-budget-provider'
import CounterProposalProvider from '_views/manage-budget-provider/counter-proposal-provider'
import ReasonDevolution from '_views/reason-devolution-budget'
import ManagementServiceOrder from '_views/management-service-order'

import ServiceFive from './views/service-five'
import ServiceForm from './views/service-form'
import MensageriaForm from './views/mensageria-form'

import BudgetVisualization from './views/budget-visualization'
import ExecutionScreen from './views/service-order/execution-screen'
import ApprovalConfirmation from './views/service-order/approval-confirmation'
import { AccessDenied, NotFound, ExpiredLink, SystemDown } from './views/error-screens'
import PaymentAdvanceTable from './views/finance/payment-advance-table'
import HelpRequestsScreen from './views/help-requests'
import HelpProceduresScreen from './views/help-requests/help-procedures'
import TradesmanView from './views/tradesman'
import PostWork from './views/post-work-details'
import DashboardReport from './views/reports/dashboard'
import PayerData from './views/finance/payer-data'
import Owner from './views/finance/owner-data'

import BatchScreen from './views/finance/manage-installments/batches/batchScreen'
import BatchesScreen from './views/finance/manage-installments/batches/batchesScreen'
import BankReturnFiles from './views/finance/manage-installments/bankReturnFiles'
import { GenerateContract } from './views/generate-contract'
import InstallmentsReceipts from './views/finance/tradesman/receipts'
import { ReferaPresentation } from './views/quinto-andar/refera-presentation'
import { StepNotificationMessage } from './views/step-notification-message-setup'
import ReturnFileDetails from './views/finance/manage-installments/bankReturnFiles/returnFileDetails'
import PaymentAdvanceDetails from './views/finance/payment-anticipation-details'
import ClassificationManagementView from './views/classification-management'
import VIPClientesManagementView from './views/vip-clients-management/vip-clients-list'
import ClassificationForm from './views/classification-form'
import LandingPage from './views/landing-page'
import InstallmentDetails from './views/finance/installment-details'
import TradesmanAnticipationRequests from './views/finance/tradesman/anticipation-requests'
import { SendRatingLink } from './views/send-rating-link'
import { NFForm } from './views/service-order/NF-form'
import Receipts from './views/finance/receipts'
import ReceiptDetails from './views/finance/receipts/receipt-details'
import CategoryScreen from './views/category'
import BudgetTemplatesView from '_views/tradesman/tradesman-template-list'
import BudgetTemplateForm from '_views/tradesman/tradesman-template-form'
import ServiceScreen from './views/service'
import { BudgetServices } from './views/budget-services'
import AsaasExport from './views/finance/receipts/asaas-export'
import ProblemsScreen from './views/problems'
import { CreateAndEditReferaUsersForm, ListReferaUsers } from './views/refera-users'
import { Profiles } from './views/profiles'
import { NewService } from './views/new-service'
import { LandingPageB2C } from './views/landing-page-b2c'
import { ProfilePermissionsData } from './views/profile-permissions-data'
import { ProfilePermissionsActions } from './views/profile-permissions-actions'
import { AddPermissions } from './views/add-permissions'
import { PermissionGroup } from './views/permission-group'
import { GroupPermissionsActions } from './views/group-permissions-actions'
import { GroupPermissionsData } from './views/group-permissions-data'
import { LandingPageAgency } from './views/landing-page-agency'
import { ChargeFiles } from './views/finance/manage-installments/chargeFiles'
import ChargeFileDetails from './views/finance/manage-installments/chargeFiles/charge-files-details'
import ListLeadProviders from './views/provider/list-leads'

import CoverageRule from '_views/coverage-rule'
import ReferaCoverage from '_views/coverage-rule/refera-coverage'
import { BudgetViewV2 } from './views/budget-view-v2'

const Router = () => (
  <ReachRouter primary={false}>
    <App path="/">
      <ManagementServiceOrder path={ROUTES.MANAGE_ORDERS} />

      <Profiles path={`${ROUTES.PROFILES}`} />
      <ProfilePermissionsData path={`${ROUTES.PROFILES}/dados/:perfilId`} />
      <ProfilePermissionsActions path={`${ROUTES.PROFILES}/acoes/:perfilId`} />
      <GroupPermissionsActions path={`${ROUTES.GROUP}/acoes/:groupId`} />
      <GroupPermissionsData path={`${ROUTES.GROUP}/dados/:groupId`} />
      <AddPermissions path={`${ROUTES.MANAGER_PERMISSIONS}`} />
      <AddPermissions path={`${ROUTES.MANAGER_PERMISSIONS}/:groupId`} />
      <PermissionGroup path={`${ROUTES.GROUP_PERMISSIONS}`} />

      <ServiceOrder path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId`} />
      <ServiceOrder path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/orcamento/:budgetId`} />
      <BudgetServices
        path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/orcamento/:budgetId/servicos`}
      />
      <ExecutionScreen
        path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/orcamento/:budgetId/execucao`}
      />
      <ApprovalConfirmation
        path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/orcamento/:budgetId/comprovante-aprovacao`}
      />
      <Agency path={`${ROUTES.AGENCY}/:agencyId`} />
      <Agency path={ROUTES.CREATE_AGENCY} />
      <AgencyUser path={`${ROUTES.AGENCY}/:agencyId/criar-usuario`} />
      <AgencyUser path={`${ROUTES.AGENCY}/:agencyId/usuario/:userId`} />
      <PaymentAdvanceTable path={`${FINANCE_ROUTES.PAYMENT_ADVANCE}`} />
      <PaymentAdvanceDetails path={`${FINANCE_ROUTES.PAYMENT_ADVANCE}/:paymentAnticipationId`} />
      <PaymentAdvanceDetails
        path={`${ROUTES.INSTALLMENTS_RECEIPTS}/antecipacoes/:paymentAnticipationId`}
      />
      <MensageriaForm path={`${ROUTES.MENSAGERIA}`} />
      <CoverageRule path={`${ROUTES.COVERAGE_RULES}`} />
      <ReferaCoverage path={`${ROUTES.REFERA_COVERAGE}/:budgetId`} />
      <CategoryScreen path="/categoria" />
      <ProblemsScreen path={ROUTES.PROBLEMS} />
      <ServiceScreen path="/servico" />
      <PayerData
        path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/orcamento/:budgetId/dados-pagador`}
      />
      <Owner path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/dados-proprietario`} />
      <PayerData
        path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/orcamento/:budgetId/dados-pagador/aprovar`}
      />
      <Provider path={`${ROUTES.PROVIDER}/:providerId`} />
      <Provider path={ROUTES.CREATE_PROVIDER} />
      <ManageInstallments path={FINANCE_ROUTES.MANAGE_INSTALLMENTS} />
      <ChargeFiles path={FINANCE_ROUTES.CHARGE_FILES} />
      <BatchScreen path={`${FINANCE_ROUTES.BATCHES}/:batchId`} />
      <BatchesScreen path={FINANCE_ROUTES.BATCHES} />
      <BankReturnFiles path={FINANCE_ROUTES.BANK_RETURN_FILES} />
      <ManageProviders path={ROUTES.MANAGE_PROVIDERS} />
      <ManageAgencies path={ROUTES.MANAGE_AGENCIES} />
      <Widgets path={WIDGETS_ROUTE.WIDGETS} />
      <HelpRequestsScreen path={ROUTES.HELP_REQUESTS} />
      <HelpProceduresScreen path={`${ROUTES.HELP_REQUESTS}/tramites/:id`} />
      <NotFound default path="nao-encontrado" />
      <AccessDenied path="acesso-negado" />
      <TradesmanView path={ROUTES.NEW_ORDERS} />
      <BudgetTemplatesView path={ROUTES.BUDGET_TEMPLATE} />
      <BudgetTemplateForm path={`${ROUTES.BUDGET_TEMPLATE}/template`} />
      <BudgetTemplateForm path={`${ROUTES.BUDGET_TEMPLATE}/template/:templateId`} />
      <Redirect from={ROUTES.DELAYED_ORDERS} to={`${ROUTES.NEW_ORDERS}?delayed=true`} noThrow />
      <DashboardReport path={ROUTES.REPORTS} />
      <HomeTradesman path={ROUTES.TRADESMAN} />
      <InstallmentsReceipts path={`${ROUTES.INSTALLMENTS_RECEIPTS}`} />
      <TradesmanAnticipationRequests path={`${ROUTES.INSTALLMENTS_RECEIPTS}/antecipacoes`} />
      <InstallmentDetails path={`${ROUTES.INSTALLMENTS_RECEIPTS}/parcela/:installmentId`} />
      <InstallmentDetails
        path={`${ROUTES.INSTALLMENTS_RECEIPTS}/antecipacoes/:paymentAnticipationId/parcela/:installmentId`}
      />
      <InstallmentDetails path={`${FINANCE_ROUTES.MANAGE_INSTALLMENTS}/parcela/:installmentId`} />
      <InstallmentDetails
        path={`${FINANCE_ROUTES.PAYMENT_ADVANCE}/:paymentAnticipationId/parcela/:installmentId`}
      />
      <Receipts path={`${FINANCE_ROUTES.RECEIPTS}`} />
      <Receipts path={`${ROUTES.FINANCE_MANAGER}`} />
      <ReceiptDetails path={`${FINANCE_ROUTES.RECEIPT_DETAILS}/:budgetReceivableId`} />
      <ReceiptDetails path={`${ROUTES.FINANCE_MANAGER}/detalhes-recebimento/:budgetReceivableId`} />
      <AsaasExport path={FINANCE_ROUTES.RECEIPTS_ASAAS} />
      <StepNotificationMessage path={`${ROUTES.STEP_MESSAGE}/novo`} />
      <StepNotificationMessage path={`${ROUTES.STEP_MESSAGE}/:messageId`} />
      <ReturnFileDetails path={`${FINANCE_ROUTES.BANK_RETURN_FILES}/:processingDate`} />
      <ChargeFileDetails path={`${FINANCE_ROUTES.CHARGE_FILES}/:processingDate`} />
      <ClassificationManagementView
        path={`${ROUTES.AGENCY}/:agencyId${ROUTES.CLASSIFICATION_LIST}`}
      />
      <ClassificationManagementView
        path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId${ROUTES.CLASSIFICATION_LIST}`}
      />
      <ClassificationForm path={`${ROUTES.AGENCY}/:agencyId${ROUTES.CLASSIFICATION_FORM}`} />
      <ClassificationForm
        path={`${ROUTES.AGENCY}/:agencyId${ROUTES.CLASSIFICATION_FORM}/:classificationId`}
      />
      <VIPClientesManagementView path={`${ROUTES.AGENCY}/:agencyId${ROUTES.VIP_CLIENTS_LIST}`} />
      <ServiceOrderDetails path={`${ROUTES.DETAIL_SERVICE_ORDER}/:serviceOrderId`} />
      <ServiceOrderDetails path={`${ROUTES.DETAIL_SERVICE_ORDER}/:serviceOrderId/criar-proposta`} />
      <SendRatingLink path={`${ROUTES.SEND_RATING_LINK}/:serviceOrderId`} />
      <NFForm path={`${ROUTES.NF_FORM}/:serviceOrderId/orcamento/:budgetId`} />
      <LandingPage path={ROUTES.LANDING} />
      <GenerateContract path={ROUTES.CONDOLIVRE_CONTRACT} />
      <ListReferaUsers path={`${ROUTES.REFERA_USERS}`} />
      <CreateAndEditReferaUsersForm path={`${ROUTES.REFERA_USERS}/novo`} />
      <CreateAndEditReferaUsersForm path={`${ROUTES.REFERA_USERS}/:userId`} />
      <NewService path={`${ROUTES.NEW_SERVICE_LOGGED}/:agencySlug`} />
      <ListLeadProviders path={ROUTES.LEAD_PROVIDERS} />
      <ServiceOrderDetails
        path={`${ROUTES.PROVIDER}${ROUTES.DETAIL_SERVICE_ORDER}/:serviceOrderId`}
      />
      <ServiceOrderDetails
        path={`${ROUTES.PROVIDER}/${ROUTES.DETAIL_SERVICE_ORDER}/:serviceOrderId/criar-proposta`}
      />
      <BudgetView path={`${ROUTES.BUDGET_VIEW}/:budgetId`} />
      <BudgetViewV2 path={`${ROUTES.SERVICE_ORDERS}/:serviceOrderId/orcamentos/:budgetId`} />
      <BudgetViewV2 path={`${ROUTES.SERVICE_ORDERS}/:serviceOrderId/orcamentos/novo`} />
    </App>
    <RecoverPassword path={ROUTES.RECOVER_PASSWORD} />
    <BudgetPDF path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/orcamento/:budgetId/pdf`} />
    <BudgetVisualization path="/chamado/:serviceOrderId/detalhes-orcamento/:budgetId" />
    <CounterProposalProvider
      path={`${ROUTES.COUNTER_PROPOSAL_PROVIDER}/:serviceOrderId/:budgetId`}
    />
    <ApprovedBudgetProvider path={`${ROUTES.BUDGET_PROVIDER}/:serviceOrderId/:budgetId`} />
    <ExecutionScreen
      path={`${ROUTES.SERVICE_ORDER}/:serviceOrderId/orcamento/:budgetId/execucao/prestador`}
    />
    <Login path={ROUTES.LOGIN} />
    <PasswordRecover path={ROUTES.PASSWORD_RECOVER} />
    <ServiceFive path="/reprove-service/:serviceOrderId" />
    <ReferaPresentation path="/solicitar-orcamento-refera/:serviceOrderId" />
    <ServiceForm path={ROUTES.SETUP_AGENCY_FORM_NO_SLUG} />
    <GenerateContract path={ROUTES.GENERATE_CONTRACT} />
    <ServiceForm path={ROUTES.SETUP_AGENCY_FORM} />
    <NewService path={`${ROUTES.NEW_SERVICE}/:agencySlug`} />
    <ApplicantValuation path="/avaliacao-solicitante/:serviceOrderId/:ratingId" />
    <ApplicantValuation path="/avaliacao-solicitante/:serviceOrderId/" />
    <ApplicantValuationConfirm path="/avaliacao-solicitante-confirmar" />
    <ReasonDevolution path="reason-devolution-budget/:serviceOrderId/:budgetId" />
    <PostWork path="/servico-finalizado/:serviceOrderId" />
    <AccessDenied path={ROUTES.ACCESS_DENIED} fullscreen />
    <ExpiredLink path={ROUTES.LINK_EXPIRED} fullscreen />
    <NotFound default fullscreen />
    <SystemDown path={ROUTES.MAINTENANCE_SYSTEM} fullscreen />
    <LandingPageB2C path={ROUTES.LANDING_PAGE_B2C} fullscreen />
    <LandingPageAgency path={ROUTES.LANDING_PAGE_B2C_AGENCY} fullscreen />
  </ReachRouter>
)

export default Router
